import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { adminService } from "../../../services/admin-service";
import { selectUserScreenButton } from "../../../reducers/authSlice";
import { USER_SCREEN_BUTTONS } from "../../../utils/auth";

import { Button } from "../../common/buttons";

import "./styles.less";
import { CheckboxInput, InputLabel, TextInput, TimePicker } from "../../common/form-controls";
import {
    setCoreSetting,
    setSodSetting,
    selectCoreSettings,
    selectSodSettings,
    fetchCoreSettings,
    fetchSodSettings,
    saveSodSettings,
    saveCoreSettings,
    setBusy,
    setDialogType,
    selectChangedCoreSettings,
    selectChangedSodSettings,
    SERVICE_ID,
} from "../../../reducers/admin/adminSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { SubmitDialog, UserLogDialog } from "../../common/dialogs";
import useLogs from "../../common/hooks/useLogs";


const SettingInput = ({ module, settingId, value, type, disabled, error, label }) => {
    const dispatch = useDispatch()

    const setValue = useCallback((value) => {

        const setFunc = module == SERVICE_ID.CORE ? setCoreSetting : setSodSetting
        dispatch(setFunc({
            settingId, value
        }))
    }, [dispatch, settingId])

    const onChange = useCallback((newValue) => {
        switch (type) {
            case "int":
                if (newValue === "") {
                    setValue(newValue)
                    return
                }

                newValue = parseInt(newValue)

                if (isNaN(parseInt(newValue))) {
                    newValue = value || ""
                }
                break
            case "string":
                break
            case "bool":
                newValue = !!newValue

                break
            case "time": {
                const formatter = new Intl.DateTimeFormat('ru-RU', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

                newValue = formatter.format(newValue);

                break
            }
            default:

        }

        setValue(newValue)
    }, [type, value, setValue])

    switch (type) {
        case "bool":
            return <CheckboxInput
                label={label}
                checked={value}
                disabled={disabled}
                onChange={(event) => onChange(event.target.checked)}
            />
        case "time": {
            let hours = 0
            let minutes = 0

            try {
                const splittedDate = value.split(":")

                hours = splittedDate[0]
                minutes = splittedDate[1]
            } catch (e) {
                console.log(e)
            }

            const dateValue = new Date()
            dateValue.setHours(hours)
            dateValue.setMinutes(minutes)


            return (
                <Box>
                    <InputLabel className="input-label" shrink={true}>
                        {label}
                    </InputLabel>
                    <TimePicker
                        value={dateValue}
                        onChange={onChange}
                    />
                </Box>
            )
        }
        default:
            return (
                <TextInput
                    label={label}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    disabled={disabled}
                    error={error && error?.value === value}
                    errorMessage={error?.message}
                />
            );
    }
};


const AdminActionButton = ({
    children,
    onClick,
    disabled,
    sx = {}
}) => {
    return (
        <Button
            className="admin-action-button"
            variant="outlined"
            onClick={onClick}
            sx={{ ...sx }}
            disabled={disabled}
        >
            {children}
        </Button>
    )
};

const AdminPage = () => {
    const genRulesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.GEN_RULES));
    const clearMatricesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.CLEAR_MATRICES));
    const coreSettings = useSelector(state => selectCoreSettings(state))
    const sodSettings = useSelector(state => selectSodSettings(state))
    const busy = useSelector(state => state.admin.admin.busy)
    const dialogType = useSelector(state => state.admin.admin.dialogType)
    const changedCoreSettings = useSelector(selectChangedCoreSettings)
    const changedSodSettings = useSelector(selectChangedSodSettings)
    const intl = useIntl()

    const { logs: logsCore, getLogs: getLogsCore, openLogs: openLogsCore, setOpenLogs: setOpenLogsCore } = useLogs("core-settings")
    const { logs: logsSod, getLogs: getLogsSod, openLogs: openLogsSod, setOpenLogs: setOpenLogsSod } = useLogs("sod-settings")

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCoreSettings())
        dispatch(fetchSodSettings())
    }, [dispatch])

    const handleGenRulesClick = async () => {
        dispatch(setBusy(true));

        try {
            await adminService.generateRules();
        } catch (error) {
            console.error(error);
        }

        dispatch(setBusy(false));
    };

    const handleDeleteMatrixClick = async () => {
        dispatch(setBusy(true));

        try {
            await adminService.clearMatrices();
        } catch (error) {
            console.error(error);
        }

        dispatch(setBusy(false));
    };

    const handleSaveCoreSettings = async () => {
        dispatch(saveCoreSettings())
    }

    const handleSaveSodSettings = async () => {
        dispatch(saveSodSettings())
    }

    return (
        <>
            <Box>
                {genRulesButton && (
                    <AdminActionButton
                        onClick={handleGenRulesClick}
                        disabled={busy}
                    >
                        {genRulesButton.title}
                    </AdminActionButton>
                )}

                {clearMatricesButton && (
                    <AdminActionButton
                        onClick={handleDeleteMatrixClick}
                        disabled={busy}
                    >
                        {clearMatricesButton.title}
                    </AdminActionButton>
                )}

            </Box>

            <Box className="admin-settings-inputs">
                <Typography className="admin-settings-title">
                    <FormattedMessage id="admin-settings-page.core-settings.title" />
                </Typography>

                {
                    coreSettings && coreSettings.map(item => (<SettingInput
                        module={SERVICE_ID.CORE}
                        key={item.key}
                        settingId={item.key}
                        type={item.type}
                        label={item.description}
                        value={item.value}
                    />))
                }

                <Box sx={{
                    marginTop: "25px",
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <AdminActionButton
                        onClick={handleSaveCoreSettings}
                        disabled={changedCoreSettings && changedCoreSettings.length === 0}
                    >
                        <FormattedMessage id="admin-settings-page.btn-save-settings" />
                    </AdminActionButton>

                    <AdminActionButton
                        onClick={() => getLogsCore()}
                    >
                        <FormattedMessage id="admin-settings-page.btn-logs" />
                    </AdminActionButton>
                </Box>

            </Box>
            {sodSettings.length > 0 &&
                <Box marginTop="40px" className="admin-settings-inputs">
                    <Typography className="admin-settings-title">
                        <FormattedMessage id="admin-settings-page.sod-settings.title" />
                    </Typography>

                    {
                        sodSettings && sodSettings.map(item => (<SettingInput
                            module={SERVICE_ID.SOD}
                            key={item.key}
                            settingId={item.key}
                            type={item.type}
                            label={item.description}
                            value={item.value}
                        />))
                    }

                    <Box sx={{
                        marginTop: "25px",
                        display: "flex",
                        flexDirection: "row"
                    }}>

                        <AdminActionButton
                            onClick={handleSaveSodSettings}
                            disabled={changedSodSettings && changedSodSettings.length === 0}
                        >
                            <FormattedMessage id="admin-settings-page.btn-save-settings" />
                        </AdminActionButton>

                        <AdminActionButton
                            onClick={() => getLogsSod()}
                        >
                            <FormattedMessage id="admin-settings-page.btn-logs" />
                        </AdminActionButton>
                    </Box>

                </Box>
            }

            {busy && (
                <CircularProgress size="34px" />
            )}

            <SubmitDialog
                open={dialogType && dialogType === "success"}
                title={""}
                message={intl.formatMessage({ id: "admin-settings-page.success-save" })}
                onCancelClick={() => { dispatch(setDialogType(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    type: "close",
                    onClick: () => { dispatch(setDialogType(null)) }
                }]}
                disableRestoreFocus
            />

            <UserLogDialog
                open={openLogsCore}
                logs={logsCore}
                onClose={() => setOpenLogsCore(false)}
                disableRestoreFocus
            />

            <UserLogDialog
                open={openLogsSod}
                logs={logsSod}
                onClose={() => setOpenLogsSod(false)}
                disableRestoreFocus
            />
        </>
    );
};

AdminActionButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object
};

SettingInput.propTypes = {
    module: PropTypes.string,
    settingId: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool
};

export default AdminPage;
